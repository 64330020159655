import React, { useState, useContext, useEffect } from "react";
import { Navbar } from "../components/navbar/Navbar.jsx";
import { HeroSmall } from "../components/hero/HeroSmall";
import { Footer } from "../components/footer/Footer.jsx";
import { UserContext } from "../UserContext";
import { isAuthenticated, refresh } from "../middleware/auth";
import { getUserInfo, getStats } from "../middleware/core";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import TotalFlights from "../components/stats/TotalFlights.jsx";

import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { Link } from "react-router-dom";

export const Stats = () => {
  const [stats, setStats] = useState([])

  const [loading, setLoading] = useState(true);

  const { userContextData, setUserContextData } = useContext(UserContext);

  useEffect(() => {
    const getInitialData = async () => {
      if (isAuthenticated()) {
        const user = await refresh();
        const userInfo = await getUserInfo(user.public_id);
        user.info = userInfo;
        setUserContextData(user);
        const data = (await getStats()).data;

        data.aircraft.sort((a, b) => a.total_flights < b.total_flights ? 1 : -1);
        data.airports.sort((a, b) => a.total_flights < b.total_flights ? 1 : -1);
        data.users.sort((a, b) => a.total_flight_time < b.total_flight_time ? 1 : -1);

        let limit = 10;

        if (data.users.length < limit) limit = data.users.length;

        data.airports = data.airports.splice(0, limit);
        data.users = data.users.splice(0, limit);

        data.flights_by_180_days = data.flights_by_180_days.filter(f => f.close !== 0)

        setStats(data);
        setLoading(false);
      }
    }

    getInitialData()
  }, []);

  return (
    <>
      <div className="section-intro">
        <Navbar user={userContextData} />
        <HeroSmall title="Airline Statistics" subTitle="Crew Center" />
      </div>
      <div className="uk-section uk-padding-medium">
        <div className="uk-container">
          <div className="uk-grid-small flights-stats" uk-grid="true">
            {loading ? (
              <>
                <div className="uk-width-2-3@m">
                  <Skeleton height={290} />
                </div>
                <div className="uk-width-1-3@m">
                  <Skeleton height={290} />
                </div>
                <div className="uk-width-1-3@m">
                  <Skeleton height={290} />
                </div>
                <div className="uk-width-2-3@m">
                  <Skeleton height={290} />
                </div>
                <div className="uk-width-1-3@m">
                  <Skeleton height={290} />
                </div>
                <div className="uk-width-1-3@m">
                  <Skeleton height={290} />
                </div>
                <div className="uk-width-1-3@m">
                  <Skeleton height={290} />
                </div>
              </>
            ) :
              <><div className="uk-width-2-3@m">
                <div id="stats-total-flights" style={{ height: '300px', position: 'relative', boxShadow: '0 5px 15px rgb(0 0 0 / 18%)' }}>
                  <div className="stat-header" style={{ position: 'absolute', zIndex: 999, left: 25, top: 25 }}>
                    <p className="uk-text-left uk-margin-remove uk-text-large uk-light">Total Flights</p>
                    <h3 className="uk-card-title uk-text-left uk-margin-remove uk-text-bold uk-light">{stats.total_flights}</h3>
                  </div>
                  <ParentSize>{({ width, height }) => <TotalFlights width={width} height={height} data={stats.flights_by_180_days}></TotalFlights>}</ParentSize>
                </div>
              </div>
                <div className="uk-width-1-3@m">
                  <div style={{ height: '300px', position: 'relative', boxShadow: '0 5px 15px rgb(0 0 0 / 18%)' }}>
                    <div className="uk-card uk-card-primary uk-card-body bg-image" style={{ height: '100%', background: 'url("./images/stats-8.jpg")', boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.5), 0 5px 15px rgb(0 0 0 / 18%)', backgroundSize: 'cover' }}>
                      <p className="uk-text-left uk-margin-remove uk-text-large">Active Pilots</p>
                      <h3 className="uk-card-title uk-text-left uk-margin-remove uk-text-bold">{stats.active_pilots}</h3>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-3@m">
                  <div className="uk-card uk-card-default uk-padding-small">
                    <h3 className="uk-heading-line uk-text-left uk-padding-small uk-margin-remove-bottom"><span>Top Airports 🔥</span></h3>
                    <table className="uk-table uk-table-middle uk-table-divider uk-table-hover uk-table-responsive uk-margin-remove-top">
                      <thead>
                        <tr>
                          <th className="uk-width-small">Airport ICAO</th>
                          <th>ARRs & DEPs</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.airports.map(a => (
                          <tr>
                            <td>{a.icao}</td>
                            <td>{a.total_flights}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="uk-width-2-3@m">
                  <div className="uk-card uk-card-default uk-padding-small">
                    <h3 className="uk-heading-line uk-text-left uk-padding-small uk-margin-remove-bottom"><span>Top Pilots 🏆</span></h3>
                    <table className="uk-table uk-table-middle uk-table-divider uk-table-hover uk-table-responsive uk-margin-remove-top">
                      <thead>
                        <tr>
                          <th className="uk-width-small">Username</th>
                          <th>Total Block Flight Time</th>
                          <th>Total Flights</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.users.map(u => (
                          <tr>
                            <td>{u.username}</td>
                            <td>{u.total_flight_time.toFixed(1)}</td>
                            <td>{u.total_flights}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="uk-width-1-3@m">
                  <div style={{ height: '300px', position: 'relative', boxShadow: '0 5px 15px rgb(0 0 0 / 18%)' }}>
                    <div className="uk-card uk-card-primary uk-card-body bg-image" style={{ height: '100%', background: 'url("./images/stats-5.jpg")', boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.5), 0 5px 15px rgb(0 0 0 / 18%)', backgroundSize: 'cover' }}>
                      <p className="uk-text-left uk-margin-remove uk-text-large">Most Used Aircraft</p>
                      <h3 className="uk-card-title uk-text-left uk-margin-remove uk-text-bold">{stats.aircraft[0].type}</h3>
                      {stats.aircraft.splice(0, 4).map(a => (
                        <div className="uk-grid-small uk-padding-remove" uk-grid="true">
                          <div className="uk-width-expand uk-text-left" uk-leader="true">{a.type}</div>
                          <div>{a.total_flights} Flights</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-3@m">
                  <div style={{ height: '300px', position: 'relative', boxShadow: '0 5px 15px rgb(0 0 0 / 18%)' }}>
                    <div className="uk-card uk-card-primary uk-card-body bg-image" style={{ height: '100%', background: 'url("./images/stats-6.jpg")', boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.5), 0 5px 15px rgb(0 0 0 / 18%)', backgroundSize: 'cover' }}>
                      <p className="uk-text-left uk-margin-remove uk-text-large">Total Hours</p>
                      <h3 className="uk-card-title uk-text-left uk-margin-remove uk-text-bold">{stats.total_hours.toFixed(1)}</h3>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-3@m">
                  <div style={{ height: '300px', position: 'relative', boxShadow: '0 5px 15px rgb(0 0 0 / 18%)', overflow: 'hidden' }}>
                    <div className="uk-light uk-background-secondary uk-padding uk-text-left bg-image" style={{ height: '100%', background: 'url("./images/stats-4.jpg")', boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.5), 0 5px 15px rgb(0 0 0 / 18%)', backgroundSize: 'cover' }}>
                      <h3>Missing Something?</h3>
                      <p>Would you like to request a statistic implementation? Please let us know using the form below.</p>
                      <a href="https://forms.office.com/r/qCnkZnHYD2" className="uk-button uk-button-default">Request a stat</a>
                    </div>
                  </div>
                </div></>}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
